<template>
    <BT-Blade-Item
        :bladesData="bladesData"
        bladeName="client-order"
        :loadingMsg="loadingMsg"
        navigation="client-orders"
        :refreshToggle="refreshToggle"
        title="Client Order"
        :onPullSuccessAsync="pullOrder">
        <template slot-scope="{ item }">
            <v-list-item v-if="item != null && item.buyer != null" dense>
                <v-list-item-avatar>
                    <v-img :src="companyLogoURL(item.buyerID)" class="mx-auto">
                        <template v-slot:placeholder>
                            <v-icon color="primary">mdi-account</v-icon>
                        </template>
                    </v-img>
                </v-list-item-avatar>
                <v-list-item-title>
                    {{ item.buyer.companyName }}
                </v-list-item-title>
            </v-list-item>
            
            <BT-Field-String
                v-model="item.courierOrderNumber"
                label="Courier Order #"
                horizontal />
                
            <BT-Field-Date
                v-model="item.dueDate"
                label="Due On" />

            <v-list-item dense v-if="item.departureLocation != null">
                <v-list-item-content>
                    <v-list-item-subtitle>{{ item.isPickup ? 'Customer Picking Up From' : 'Departing From' }}</v-list-item-subtitle>
                    <v-list-item-title>{{ item.departureLocation | toLocationLine }}</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
            <v-list-item dense v-else>
                <v-list-item-content>
                    <v-list-item-subtitle>{{ item.isPickup ? 'Customer Requesting To Pickup From' : 'Requested Pickup From' }}</v-list-item-subtitle>
                    <v-list-item-title>{{ item.requestedDepartureLocation | toLocationLine }}</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
            <v-list-item dense v-if="!item.isPickup && item.destinationLocation != null">
                <v-list-item-content>
                    <v-list-item-subtitle>Destination</v-list-item-subtitle>
                    <v-list-item-title v-if="item.destinationLocation != null">{{ item.destinationLocation | toLocationLine }}</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
            <v-list-item dense v-else-if="!item.isPickup">
                <v-list-item-content>
                    <v-list-item-subtitle>Request Destination</v-list-item-subtitle>
                    <v-list-item-title>{{ item.requestedDestinationLocation | toLocationLine }}</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
            <v-list-item v-if="item.isApproved === false">
                <v-list-item-content>
                    <v-list-item-subtitle>Status</v-list-item-subtitle>
                    <v-list-item-title>Rejected</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
            <v-list-item v-else dense>
                <v-list-item-content>
                    <v-list-item-subtitle>Status</v-list-item-subtitle>
                    <v-list-item-title v-if="item.fulfilledOn != null">Fulfilled: {{ item.fulfilledOn | toShortDateAndTime }}</v-list-item-title>
                    <v-list-item-title v-else-if="item.isPlanned">Route Planned</v-list-item-title>
                    <v-list-item-title v-else>Awaiting Route Plan</v-list-item-title>
                    <v-list-item-title v-if="item.isFinalLeg">Final Leg</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
            
            <v-divider class="my-1" />

            <v-row>
                <v-col cols="6">
                    <BT-Blade-Button
                        v-if="!item.isPlanned && item.isApproved && item.fulfilledOn == null && item.sellerID == item.buyerID"
                        class="my-2"
                        @click="planDirectRoute(item)"
                        :icon="item.isPickup ? 'mdi-pickaxe' : 'mdi-map-marker-distance'"
                        :label="item.isPickup ? 'Pickup' : 'Move Directly'"
                        text="Move Directly" />
                </v-col>
                <v-col cols="6">
                    <BT-Dialog
                        v-if="!item.isPickup && !item.isPlanned && item.isApproved && item.fulfilledOn == null && item.sellerID == item.buyerID"
                        block
                        buttonClass="my-2 primary"
                        icon="mdi-map-marker-question-outline"
                        @ok="res => { createRoute(res, item) }"
                        :getOnOpenAsync="getRouteSuggestionsAsync"
                        :item="item"
                        label="Route Suggestions"
                        small
                        text="Suggest Route"
                        title="Route Suggestion"
                        width="550">
                        <template v-slot="{ item }">
                            <v-list v-if="isLengthyArray(item.routeMovements)">
                                <v-list-item v-for="(route, index) in item.routeMovements" :key="index" small>
                                    <v-list-item-content>
                                        <v-list-item-subtitle v-if="index == 0">
                                            <BT-Entity
                                                :itemValue="route.departureLocationID"
                                                navigation="public-locations" 
                                                single
                                                textFilter="toFamiliarLocationLine"
                                                useLocalCache />
                                        </v-list-item-subtitle>
                                        <v-list-item-subtitle>To</v-list-item-subtitle>
                                        <v-list-item-subtitle>
                                            <BT-Entity
                                                :itemValue="route.destinationLocationID"
                                                navigation="public-locations" 
                                                single
                                                textFilter="toFamiliarLocationLine"
                                                useLocalCache />
                                        </v-list-item-subtitle>
                                    </v-list-item-content>
                                    <v-list-item-action>
                                        <v-list-item-action-text>{{ route.dueArrivalOn | toDayShortDate }}</v-list-item-action-text>
                                    </v-list-item-action>
                                </v-list-item>
                            </v-list>
                            <div v-else>No Suggestions Found</div>
                        </template>
                    </BT-Dialog>
                </v-col>
                <v-col cols="6">
                    <BT-Blade-Button
                        v-if="item.isApproved == null"
                        @click="approveCourierOrder(item)"
                        icon="mdi-check"
                        label="Approve Order" />
                </v-col>
                <v-col cols="6">
                    <BT-Blade-Button
                        v-if="(item.isApproved == null || (item.isApproved === true && item.fulfilledOn == null))"
                        @click="rejectCourierOrder(item)"
                        icon="mdi-check"
                        buttonClass="error"
                        label="Reject Order" />
                </v-col>
                <v-col cols="6">
                    <BT-Blade-Button
                        v-if="item.sellerID == item.buyerID && item.fulfilledOn != null && !item.isBilled"
                        @click="invoiceCourierOrder(item)"
                        icon="mdi-receipt"
                        label="Invoice Courier Order" />
                </v-col>
            </v-row>
<!--             
            <BT-Dialog
                v-if="!item.isPickup && !item.isPlanned && item.isApproved && item.fulfilledOn == null"
                icon="mdi-map-marker-question-outline"
                @ok="res => { createRoute(res, item) }"
                :getOnOpenAsync="getRouteSuggestionsAsync"
                :item="item"
                label="Route Suggestions"
                small
                title="Route Suggestion"
                width="450">
                <template v-slot="{ item }">
                    <v-list v-if="isLengthyArray(item.routeMovements)">
                        <v-list-item v-for="(route, index) in item.routeMovements" :key="index">
                            <v-list-item-content>
                                <v-list-item-title v-if="index == 0">
                                    <BT-Entity 
                                        navigation="public-locations" 
                                        v-model="route.departureLocationID"
                                        textFilter="toLocationLine" />
                                </v-list-item-title>
                                <v-list-item-subtitle>To</v-list-item-subtitle>
                                <v-list-item-title>
                                    <BT-Entity 
                                        navigation="public-locations" 
                                        v-model="route.destinationLocationID"
                                        textFilter="toLocationLine" />
                                </v-list-item-title>
                            </v-list-item-content>
                            <v-list-item-action>
                                <v-list-item-action-text>{{ route.dueArrivalOn | toDayMonth }}</v-list-item-action-text>
                            </v-list-item-action>
                        </v-list-item>
                    </v-list>
                    <div v-else>No Suggestions Found</div>
                </template>
            </BT-Dialog> -->
<!-- 
            <BT-Blade-Button
                v-if="item.isApproved == null"
                @click="approveCourierOrder(item)"
                icon="mdi-check"
                label="Approve Order" />

            <BT-Blade-Button
                v-if="(item.isApproved == null || (item.isApproved === true && item.fulfilledOn == null))"
                @click="rejectCourierOrder(item)"
                icon="mdi-check"
                buttonClass="error"
                label="Reject Order" />

            <BT-Blade-Button
                v-if="item.fulfilledOn != null && !item.isBilled"
                @click="invoiceCourierOrder(item)"
                icon="mdi-receipt"
                label="Invoice Courier Order" />
                 -->
            <BT-Snack v-model="msg" />
            
            <v-expansion-panels v-model="panelV">
                <BT-Blade-Expansion-Items
                    addBladeName="courier-movement"
                    :canExportCSV="false"
                    :canRefresh="false"
                    :getSelectBladeData="(bData, item) => { return { id: item.courierMovementID } }"
                    :items="item.junctures"
                    :onFilter="filterJunctures"
                    :onCanSelect="item => { return item.courierMovementID != null }"
                    showList
                    title="Movements">
                    <template v-slot:listItem="{ item }">
                        <v-list-item-content>
                            <v-list-item-title>{{ companyAccountID() != item.location.companyAccountID ? item.location.companyAccount.companyName : item.location.locationName }}</v-list-item-title>
                            <v-list-item-subtitle>{{ item.location | toLocationLine }}</v-list-item-subtitle>
                            <v-list-item-subtitle>{{ item.dueOn | toShortDate }} | {{ item.arrivedOn != null ? 'Complete' : item.courierMovementID == null ? 'No Movement' : 'Pending' }}</v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-action>
                            <v-btn
                                v-if="(item.sellerID == item.buyerID || item.seller.isManagedByAnotherAccount) && item.courierMovementID == null && item.arrivedOn == null"
                                icon
                                small
                                @click="createMovementFromJuncture(item)">
                                <v-icon small>mdi-map-marker-plus-outline</v-icon>
                            </v-btn>
                        </v-list-item-action>
                    </template>
                </BT-Blade-Expansion-Items>
            </v-expansion-panels>
        </template>
    </BT-Blade-Item>
</template>

<script>
import { firstBy } from 'thenby';

export default {
    name: 'Client-Order-Blade',
    components: {
        BTDialog: () => import('~components/BT-Dialog.vue'),
    },
    data: function() {
        return {
            junctureRefreshToggle: false,
            loadingMsg: null,
            msg: null,
            panelV: 0,
            refreshToggle: false
        }
    },
    props: {
        bladesData: null
    },
    computed: {
        companyID() {
            return this.companyAccountID();
        },
        courierOrderParams() {
            return { includeDetails: false, consignmentID: this.value };
        }
    },
    methods: {
        async approveCourierOrder(item) {
            try {
                this.loadingMsg = 'Loading';
                this.$forceUpdate();

                var data = {
                    id: item.id,
                    rowVersion: item.rowVersion,
                    departureLocationID: item.departureLocationID != null ? item.departureLocationID : item.requestedDepartureLocationID,
                    destinationLocationID: item.destinationLocationID != null ? item.destinationLocationID : item.requestedDestinationLocationID,
                    isApproved: true
                };

                await this.$BlitzIt.store.patch('client-orders', data);
                this.refreshToggle = !this.refreshToggle;
            }
            catch (err) {
                this.msg = this.extractErrorDescription(err);
            }
            finally {
                this.loadingMsg = null;
            }
        },
        async createMovementFromJuncture(item) {
            try {
                this.loadingMsg = 'Loading';
                this.$forceUpdate();

                var res = await this.$BlitzIt.store.post('courier-movements', { junctureID: item.id });
                item.courierMovementID = res.id;

            }
            catch (err) {
                this.msg = this.extractErrorDescription(err);
            }
            finally {
                this.loadingMsg = null;
            }
        },
        async createRoute(res, item) {
            try {
                this.loadingMsg = 'Loading';
                this.$forceUpdate();

                for (let i = 0; i < res.routeMovements.length; i++) {
                    const routeMovement = res.routeMovements[i];
                    
                    var movement = {
                        departureLocationID: routeMovement.departureLocationID,
                        destinationLocationID: routeMovement.destinationLocationID,
                        dueDepartureOn: routeMovement.dueDepartureOn,
                        dueArrivalOn: routeMovement.dueArrivalOn,
                        bundleID: routeMovement.bundleID,
                        courierOrderIDs: [item.id]
                    }

                    await this.$BlitzIt.store.post('courier-movements', movement);

                    item.isPlanned = true;
                    this.refreshToggle = !this.refreshToggle;
                }
            }
            catch (err) {
                this.msg = this.extractErrorDescription(err);
            }
            finally {
                this.loadingMsg = null;
            }
        },
        filterJunctures(list) {
            var l = list;
            l.sort(firstBy(x => x.sortOrder));
            return l;
        },
        getMovement(item) {
            var movement = {
                departureLocation: item.departureLocation,
                departureLocationID: item.departureLocationID,
                destinationLocation: item.destinationLocation,
                destinationLocationID: item.destinationLocationID,
                intermediateLocationID: null,
                dueDepartureOn: item.dueDate,
                dueArrivalOn: item.dueDate,
                isLastLeg: false,
                courierOrderIDs: [item.id]
            }

            return {
                movement: movement,
                order: item
            }
        },
        async getRouteSuggestionsAsync(item) {
            try {
                this.loadingMsg = 'Loading';

                var res = await this.$BlitzIt.api.getById('route-suggestions', item.id, null, null);
                if (res.data.data != null) {
                    res.data.data.routeMovements.sort(firstBy(x => x.sortNumber));
                }
                return res.data.data;
            }
            catch (err) {
                this.msg = this.extractErrorDescription(err);
            }
            finally {
                this.loadingMsg = null;
            }
        },
        // getApproveObj(item) {            
        //     return {
        //         id: item.id,
        //         rowVersion: item.rowVersion,
        //         preferStartDropoff: false,
        //         preferEndPickup: false,
        //         responderMessage: null,                                
        //         departureLocationID: item.requestedDepartureLocationID,
        //         departureLocation: item.requestedDepartureLocation,
        //         destinationLocationID: item.requestedDestinationLocationID,
        //         destinationLocation: item.requestedDestinationLocation,
        //         isPlanRouteRequest: true,
        //         isCreateMovementsRequest: true
        //     };
        // },
        async invoiceCourierOrder(item) {
            try {
                var data = { id: item.id, isBillRequest: true }

                this.loadingMsg = 'Loading';
                this.$forceUpdate();

                await this.$BlitzIt.api.patch('courier-order-fulfilling', data);
                this.$BlitzIt.store.deleteLocal('client-orders', item.id, null);
                this.$BlitzIt.navigation.navBack();
            }
            catch (err) {
                this.msg = this.extractErrorDescription(err);
            }
            finally {
                this.loadingMsg = null;
            }
        },
        async planDirectRoute(item) {
            try {
                this.loadingMsg = 'Loading';
                this.$forceUpdate();

                var movement = {
                    departureLocation: item.departureLocation || item.requestedDepartureLocation,
                    departureLocationID: item.departureLocationID || item.requestedDepartureLocationID,
                    destinationLocation: item.destinationLocation || item.requestedDestinationLocation,
                    destinationLocationID: item.destinationLocationID || item.requestedDestinationLocationID,
                    dueDepartureOn: item.dueDate,
                    dueArrivalOn: item.dueDate,
                    courierOrderIDs: [item.id],
                    isPickup: item.isPickup
                };
                
                await this.$BlitzIt.store.post('courier-movements', movement);
                
                this.refreshToggle = !this.refreshToggle;
            }
            catch (err) {
                this.msg = this.extractErrorDescription(err);
            }
            finally {
                this.loadingMsg = null;
            }
        },
        // async planRouteVia(item) {
        //     var movement = item.movement;
        //     var order = item.order;

        //     if (movement.intermediateLocationID && 
        //         movement.departureLocationID != movement.intermediateLocationID &&
        //         movement.destinationLocationID != movement.intermediateLocationID) {
                
        //         var movementOne = this.copyDeep(movement);
        //         var movementTwo = this.copyDeep(movement);
            
        //         movementOne.destinationLocationID = movement.intermediateLocationID;
        //         movementTwo.departureLocationID = movement.intermediateLocationID;

        //         try {
        //             this.isLoading = true;
        //             await this.$BlitzIt.store.post('courier-movements', movementOne);
        //             await this.$BlitzIt.store.post('courier-movements', movementTwo);
        //             order.isPlanned = true;

        //             this.junctureRefreshToggle = !this.junctureRefreshToggle;
        //         }
        //         catch (err) {
        //             this.msg = this.extractErrorDescription(err);
        //         }
        //         finally{
        //             this.isLoading = false;
        //         }
        //     }
        // },
        async pullOrder(order) {
            if (order != null && this.isLengthyArray(order)) {
                order.junctures = order.junctures.orderBy('sortOrder');
            }

            return order;
        },
        async rejectCourierOrder(item) {
            try {
                this.loadingMsg = 'Loading';
                this.$forceUpdate();

                var data = {
                    id: item.id,
                    rowVersion: item.rowVersion,
                    isApproved: false
                };

                await this.$BlitzIt.store.patch('client-orders', data);

                this.$BlitzIt.navigation.navBack();
            }
            catch (err) {
                this.msg = this.extractErrorDescription(err);
            }
            finally {
                this.loadingMsg = null;
            }
        }
    }
}
</script>